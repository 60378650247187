<template>
    <div id="content" class="actionplan">
        <template v-if="detail">
            <ActionplanDetail :id="actionplanID" @back="closeDetail"></ActionplanDetail>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <div class="right-menu-container">
                    <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="add" bLabel="button.add_template"></Button>
                    <Button bType="more" :bCallback="showMoreOptions" />
                </div>
                <div class="more-options" v-if="moreOptions">
                    <Button id="more-download-btn" bType="file" :bCallback="showExportTemplatesModal" :bLabel="exportBtnLabel" />
                </div>
            </div>

            <div class="actionplan-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- Sidebar Filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterActionplan
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        v-if="loaded && !detail"
                        @resetPageDatatable="top(true)"
                        @newFiltersApplied="clearSelected"
                    ></FilterActionplan>
                </div>
                <!-- Table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by="name"
                        :sort-asc="true"
                        :single-select="singleSelect"
                        show-select
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <!-- Name -->
                        <template #item.name="item">
                            <div class="name" @click="goToReport(item.item.id)">
                                <span :class="{ pending: item.item.status == 2 }">{{ item.item.name }} {{ item.item.surname }}</span>
                                <div class="updated-by" v-if="getEmployee(item.item.last_update_user)">
                                    {{ $t('supervise.checklists.updated_by') }} {{ getEmployee(item.item.last_update_user).name }} {{ formatLastUpdate(item.item.last_update_date) }}
                                </div>
                                <div class="created-by" v-else-if="getEmployee(item.item.created_user)">
                                    {{ $t('supervise.checklists.updated_by') }} {{ getEmployee(item.item.created_user).name }} {{ formatLastUpdate(item.item.created_date) }}
                                </div>
                            </div>
                        </template>
                        <!-- Locations -->
                        <template #item.locations="item">
                            <div @click="goToReport(item.item.id)">
                                <Tags
                                    v-if="item.item.locations && Object.values(item.item.locations).length > 0"
                                    :backImage="'location_inactive_s30.svg'"
                                    :tags="Object.values(item.item.locations)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <Tags
                                    v-else-if="item.item.groups && Object.values(item.item.groups).length > 0"
                                    :backImage="'menu_inactive_s30.svg'"
                                    :tags="Object.values(item.item.groups)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <div class="all-locations" v-else>{{ $t('product.all_available') }}</div>
                            </div>
                        </template>
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToReport(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name" :backColor="'backColor'"></Tags>
                                </template>
                            </div>
                        </template>
                        <!-- Status -->
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <Table-statuses :text="statusesText(item)" :status="statusesClass(item)"> </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>

                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.keys(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="add"
                            :buttonText="$t('button.add_template')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.actionplan_empty')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import FilterActionplan from '@/components/domain/actionplan/filter'
import ActionplanDetail from '@/components/domain/templates/detail.vue'
import EmptyTable from '@/components/ui/EmptyTable'
import { TYPE } from '@/constants'

export default {
    components: {
        FilterActionplan,
        ActionplanDetail,
        EmptyTable,
        TableStatuses
    },
    name: 'TemplateActionplan',
    data() {
        return {
            moreOptions: false,
            singleSelect: false,
            noChanges: false,
            type: TYPE.ACTIONPLAN,
            actionplanID: false,
            detail: false,
            values: false,
            selected: [],
            loaded: false,
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('user.user_name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '80%',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.sites'),
                    value: 'locations',
                    align: 'start',
                    width: '300px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.issues.table_headers.state'),
                    align: 'start',
                    sortable: true,
                    value: 'status',
                    width: '150px',
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['template/getList']()
        },
        itemsLoaded() {
            return this.$store.getters['template/getItemsLoaded']
        },
        hasAccess() {
            return this.checkPermission('/template/actionplan/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        rowsAreSelected() {
            return this.selected.length > 0 ? true : false
        },
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        },

        exportBtnLabel() {
            if (this.selected.length === 0) {
                return 'button.export_templates'
            } else if (this.selected.length === 1) {
                return 'button.export_selected_template'
            } else {
                return 'button.export_selected_templates'
            }
        }
    },
    methods: {
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        statusesText(item) {
            if (item.item.status == '-2') {
                return this.$t('template.general.status.draft')
            }

            if (item.item.status == '-3') {
                return this.$t('template.general.status.ended')
            }

            return this.$t('template.general.status.published')
        },
        statusesClass(item) {
            if (item.item.status == '-2') {
                return 'inactive'
            }

            if (item.item.status == '-3') {
                return 'ended'
            }

            return 'active'
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },

        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return {
                            name:
                                self.organizationRender.allLangs[key].name +
                                ' <b>(' +
                                parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) +
                                '%)</b>',
                            backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db'
                        }
                    }
                })
            } else return false
        },

        goToReport(item_id) {
            if (this.checkPermission('/template/actionplan/idTemplate')) {
                this.actionplanID = item_id
                this.detail = true
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('template.form.no_permission'),
                    duration: 5000
                })
            }
        },

        add() {
            var self = this

            if (this.checkPermission('/template/actionplan/add')) {
                this.$overlay.loading()
                this.$store.dispatch('actionplan/loadGalleryTemplates').then(function () {
                    var data = self.$store.getters['actionplan/getGalleryTemplates']
                    self.$overlay.hide()
                    self.$popup.galeryTemplates({
                        title: self.$t('public_templates.gallery.title'),
                        data: data,
                        textCancel: self.$t('button.cancel'),
                        callSave: function (tpl) {
                            if (tpl) {
                                self.$store.dispatch('actionplan/importGalleryTemplate', { tpl_id: tpl }).then(function (response) {
                                    if (response && response.status) {
                                        self.goToReport(response.data)
                                        self.$popup.close()
                                    } else {
                                        self.$popup.close()
                                    }
                                })
                            } else {
                                self.goToReport(false)
                            }
                        },
                        callCancel: function () {
                            self.$popup.close()
                        }
                    })
                })
            } else {
                self.$snackbar.open({
                    message: self.$t('template.form.no_permission'),
                    duration: 5000
                })
            }
        },

        closeDetail(save) {
            this.loaded = false
            this.$router.push({ name: 'TemplateActionplan', params: { save: save, noChangePageDatatable: true } })
            this.pagination.page = 1
        },

        load() {
            var self = this
            var items = this.$store.getters['template/getList']()

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '250px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(2, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(2, 0, translationHeader)
                }
            }

            self.$store.dispatch('template/loadFilters', { type: self.type }).then(function () {
                if (self.$route.params.id) {
                    self.goToReport(self.$route.params.id)
                } else if (self.$route.name == 'AddActionplanDetail') {
                    self.goToReport(false)
                } else {
                    self.$overlay.hide()
                    self.loaded = true
                }
            })
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('templatesActionplansFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('templatesActionplansFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        },

        getTemplatesIdsList(listOfTemplatesObjs) {
            if (!listOfTemplatesObjs || listOfTemplatesObjs.length === 0) {
                return null
            }

            const ids = listOfTemplatesObjs.map((template) => {
                if (template.hasOwnProperty('id')) {
                    return template.id
                }
            })

            return ids
        },

        handleExportTemplates() {
            const self = this

            self.$overlay.loading()

            if (self.selected.length > 0) {
                const idsList = self.getTemplatesIdsList(self.selected)
                self.$store.commit('template/setTemplatesToExportIds', idsList)
            } else self.$store.commit('template/setTemplatesToExportIds', null)

            self.$store.dispatch('template/exportTemplates', { type: TYPE.ISSUE }).then((url) => {
                if (url) {
                    self.$overlay.hide()

                    window.open(url, '_blank')
                    self.moreOptions = false
                    self.$popup.close()
                } else {
                    self.$overlay.hide()

                    self.moreOptions = false
                    self.$popup.close()
                    // TODO: display an error msg
                }
            })
        },

        showExportTemplatesModal() {
            const self = this

            const templatesQuantity = self.selected.length

            self.$popup.PDFDisplayOptions({
                callSave: this.handleExportTemplates,
                callCancel: () => {
                    self.moreOptions = false
                },
                config: {
                    quantity: templatesQuantity,
                    tool: 'template'
                }
            })
        },

        clearSelected() {
            this.selected = []
        },

        formatLastUpdate(date) {
            let milliseconds = date * 1000;
            let timingTo = this.$tc('assets.timing.to');
            let formattedDate = moment(milliseconds).format(`D MMM YYYY [${timingTo}] HH:mm`);

            return formattedDate;
        },

        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            return employee
        }

    },
    watch: {
        $route(to, from) {
            // if (this.$route.params.id) {
            //   this.actionplanID = this.$route.params.id;
            //   this.detail = true;
            // } else {
            //   if (this.$route.name == "ChecklistDetail") {
            //     this.detail = true;
            //   } else {
            //     to.name == 'TemplateActionplan' ? this.load() : true;
            //     this.detail = false;
            //   }
            // }
        }
    },
    created() {
        this.load()
    },
    mounted() {
        if (this.$route.params.id) {
            this.detail = true
            this.actionplanID = this.$route.params.id
        } else {
            if (this.$route.name == 'ActionplanDetail') {
                this.detail = true
            } else {
                this.detail = false
            }
        }

        this.setFiltersSidebarIsHiddenValue()
    },
    beforeRouteLeave(to, from, next) {
        var self = this
        this.loaded = false
        if (this.detail) {
            // COMMENTED FOR HASH MODE FIX
            // if (this.detail && window.location.pathname.split('/').length <= 3) {
            //     history.pushState({}, null, this.$route.path)
            // }

            if (to.params.save == true) {
                self.$overlay.show()
                self.detail = false
                self.actionplanID = false
                next()
                setTimeout(function () {
                    self.load()
                }, 100)
            } else {
                var self = this

                self.$popup.confirm({
                    message: self.$t('template.form.popup_quit_msg'),
                    textSave: self.$t('template.form.popup_quit_confirm'),
                    callSave: function () {
                        self.$overlay.show()
                        self.detail = false
                        self.actionplanID = false
                        next()
                        setTimeout(function () {
                            self.load()
                        }, 100)
                    },
                    callCancel: function () {
                        self.$popup.close()
                        self.$overlay.hide()
                    }
                })
            }
        } else {
            next()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.actionplan {
    .more-options {
        position: absolute;
        top: 60px;
        right: 24px;
        padding: 3px 3px 3px 3px;
        .btn-file {
            min-width: 183px;
        }
    }
    .actionplan-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .name{
                span {
                    font-family: $text-medium !important;
                }
                .updated-by, .created-by{
                    @include font-size(xs);
                    font-family: $text-light;
                }
            }

            .status {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: start;
                align-items: center;
            }

            .ball {
                display: inline-block;
                margin-right: 6px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
            }

            .ball-color {
                display: inline-block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                margin-right: 3px;
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
